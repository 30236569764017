@import '../../style/common.css';

.header {
    justify-content: space-between;
    background: white;
    box-shadow: 1px -1px 10px lightgrey;
    height: 8rem;
}

.header__container {
  display: flex;
  justify-content: space-between;
}

.header__title {
    font-family: var(--fancy-font-family);
    font-weight: bold;
    font-size: 4rem;
    padding: 2rem 0
}

.header__logo {
    text-align: center;
    width: 100%;
    padding: 0;
}

.header__logo--heading {
    padding: 2rem 4rem 2rem 0;
    font-size: 3rem;
    margin: 0;
}

.header__links {
    width: 100%;
}

.header__links__link {
    display: inline-block;
    padding: 3.5rem;
    font-weight: bold;
    font-size: 1.8rem;
}

.header__links__link--hover {
    display: inline-block;
    padding: 2rem;
    border: black solid 2px;
    margin: 1.5rem;
    background: black;
    color: white;
    font-weight: bold;
    font-size: 1.8rem;
    transition: 0.5s;
}

.header__links__link--active {
    color: blue;
}

.menu__selected {
  color: white !important;
  background: #b58b30;
}


:root {
    --header-inverse-top: -7.5rem;
}

.Header {

}

.MenuToggle {
  display: block;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  padding: 3rem 2.5rem 2rem 3rem;
  z-index: 999;
  cursor: pointer;
}

.MenuToggle > span:first-child {
  transform-origin: 0% 0%;
}

.MenuToggle > span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.MenuToggle > .MenuToggle--Open ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

.MenuToggle > .MenuToggle--Open ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.MenuToggle > .MenuToggle--Open ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

.MenuToggle--Open {
  position: fixed;
}

.MenuToggle--Line {
  display: block;
  width: 3.3rem;
  height: 0.4rem;
  margin-bottom: 0.5rem;
  position: relative;
  background: #b58b30;
  border-radius: 0.3rem;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

.Menu {
  top: 0;
  margin: 0;
  padding: 9rem 0 0 0;
  position: fixed;
  width: 100%;
  z-index: 998;
  height: 100%;
  opacity: 0;
  background: var(--base-background-colour);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transition: 0.5s;
  text-align: right;
}

.Menu li {
  font-size: 2rem;
}

.Menu a {
  padding: 2rem;
  text-decoration: none;
  transition: color 0.3s ease;
  display: block;
  font-weight: 400;
  text-align: center;
}

.Menu svg > fill {
  color: aquamarine;
}

.Menu--Open {
  transform: none;
  opacity: 1;
}

.Menu--Closed {
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  opacity: 0;
}

.MenuToggle {
  display: block;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  padding: 2.5rem;
  z-index: 999;
  cursor: pointer;
}

.Left {
    float: left;
}   

.Right {
    float: right;
}

.Hide {
    display: none;
}

.LinkIcon {
    width: 2.5rem;
    height: 2rem;
    display: inline-block;
    margin-right: 0.5rem;
}
  
@media screen and (min-width: 1300px) { 
  .header {
    height: 9rem;
  }

  .header__logo--heading {
    padding: 2rem 4rem 2rem 0;
    font-size: 3rem;
    margin: 0;
}

  .header__logo {
    text-align: center;
    width: 100%;
    padding: 2rem;
  }

  .header__logo--heading {
    padding: 1.6rem;
    margin: 0;
  }

  .Menu {
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transition: 0.5s;
    top: 0;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 998;
    opacity: 0;
    background: var(--base-background-colour);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transition: 0.5s;
  }

  .Menu li {
    padding: 1rem 2rem;
    font-size: 2rem;
    display: inline-block;
  }
    
  .Menu a {
    text-align: left;
  }

  .MenuToggle {
    display: none;
  }

  .Menu--Closed {
    transform: none;
    opacity: 1;
  }

  .header {
    justify-content: space-between;
    background: white;
    box-shadow: 1px -1px 10px lightgrey
  }

  .header__container {
      max-width: 150rem;
      margin: auto;
  }

  .header__logo {
      text-align: left;
      padding: 0;
      width: 50rem;
  }

  .header__links {
      text-align: right;
  }
}
