
@keyframes fadeInLeft {
    0% {
       opacity: 0;
       transform: translateX(-20px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
}   

.submittted {
    text-align: center;
    font-size: 4rem;
    padding: 9rem 0 6rem 0;
}

.bad__selection {
    background-color: indianred;
    color: white;
    font-weight: bold;
    padding: 1.5rem;
    font-size: 1.5rem;
    margin: 1rem 0;
}

.delete__button {
    background-color: indianred;
    color: white;
    border: none;
    padding: 1rem;
}

.rsvpForm {
    margin: 5rem 2rem;
}

.label {
    display: inline-block;
    width: 100%;
    font-size: 2rem;
    font-weight: bold;
}

.input {
    display: inline-block;
    padding: 1rem;
    width: 100%;
}

.guest {
    padding: 1rem 2rem;
    border: 1px solid lightgray;
    box-shadow: 1px -1px 10px lightgrey;
    margin: 0 0 2rem 0;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.submit__form {
    text-align: right;
    margin-top: 2rem;
}

.buttons-container {
    margin: 2rem 0;
    text-align: right;
}

.buttons-container__button {
    margin-left: 1rem;
}

.spacer {
    margin: 2rem 0;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

@media screen and (min-width: 1550px) {
    .block {
        margin: 0;
    }
}

@media screen and (min-width: 950px) {

    .label {
        display: inline-block;
        width: 30%;
        font-size: 2rem;
        font-weight: bold;
    }
    
    .input {
        display: inline-block;
        padding: 1rem;
        width: 70%;
    }
    
    .content {  
        flex-direction: column;
    }

    .image {
        width: 100%;
    }

    /** **/

    .hero {
        height: 750px;
        display: block;
        position: relative;
        margin-bottom: 2rem;
    }
    
    .hero::after {
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;  
        background-image: url('../../../../media/alex_steph_disney.jpg');
        height: 750px;
        background-size: cover;
        background-position: center;
        opacity: 0.7;
    }

    .hero__primary {
        font-size: 7rem;
        text-shadow: 0 0 5px white;
    }

    /** **/

    .accommodation__block {
        display: inline-block;
        width: 49%;
    }

    /** **/

    .heading {
        display: inline-block;
        background-color: black;
        color: white;
        padding: 2rem;
    }
}
