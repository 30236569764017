

.accommodation {
    margin: 1rem;
}

.accommodation__block {
    display: block;
    width: 100%;
}

.accommodation__frame {
    width: 100%;
    height: 60rem;
    border: none;
}
