.gifts {
    text-align: center;
    padding: 9rem 0 6rem 0;
    font-size: 4rem;
}

.gifts__table {
    width: 100%;
    margin: auto;
    padding: 2rem;
    border: 1px solid grey;
}

.gifts__table th {
    padding: 2rem;
}

.gifts__table tr {
    padding: 2rem;
}

.gifts__table td {
    padding: 2rem;
}

.block {
    margin: 1rem;
}