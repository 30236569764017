.image {
    width: 33%;
}

.hero {
    height: 400px;
    display: block;
    position: relative;
}

.hero::after {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;  
    background-image: url('../../../../media/alex_steph_disney.jpg');
    height: 400px;
    background-size: cover;
    background-position: center;
    opacity: 0.7;
}

.hero__primary {
    font-size: 4rem;
    text-shadow: 0 0 5px white;
}

.hero__secondary {
    font-size: 4rem;
    text-shadow: 0 0 5px white;
    font-weight: bold;
}

.whatsUp {
    position: absolute;
    text-align: center;
    margin-top: 5%;
    width: 100%;
}

/**  **/

.itinerary {
}

.itinerary__table {
    width: 100%;
    margin: auto;
    padding: 2rem;
    border: 1px solid grey;
}

.itinerary__table th {
    padding: 2rem;
}

.itinerary__table tr {
    padding: 2rem;
}

.itinerary__table td {
    padding: 2rem;
}

/** **/

.playlist {
    margin: 1rem;
}

.playlist__frame {
    width: 100%;
    height: 40rem;
}

/**  **/

.venue {

}

.venue__block {
    display: block;
}

.venue__frame {
    width: 100%;
    height: 50rem;
    border: none;
}

/**  **/

.accommodation {
    margin: 1rem;
}

.accommodation__block {
    display: block;
    width: 100%;
}

.accommodation__frame {
    width: 100%;
    height: 40rem;
    border: none;
}

/**  **/

.food {
    background-color: #eeeeee;
    padding-bottom: 3rem;
    margin: 1rem;
}

.food__items {
    display: flex;
    justify-content: space-around;
}

.food__items__item {
    width: 33.33%;
    padding: 2rem;
}

.food__item__image {
    box-shadow:  inset 0 0 30px #000000;
    border-radius: 50%;
    background: url('../../../../media/main.jpg');
    background-size: cover;
    background-position: center;
    display: inline-block;
    height: 0;
    padding-bottom: 100%;
    width: 100%;
}

/** **/

.music {
}

.music__block {
}

/** **/

.description {
    order: 1;
    flex: 2;
}

.breakdown {
    order: 2;
    flex: 1;
}
  
.heading {
    display: inline-block;
    background-color: black;
    color: white;
    padding: 2rem;
    margin: 0 0 2rem 0;
}

.punchoutLink {
    font-size: 2rem;
    padding: 1;
    display: block;
    padding: 1rem 0;
    font-weight: bold;
}

.block {
    margin: 1rem;
}

@media screen and (min-width: 1550px) {
    .block {
        margin: 0;
    }
}

@media screen and (min-width: 950px) {

    .content {  
        flex-direction: column;
    }

    .image {
        width: 100%;
    }

    /** **/

    .hero {
        height: 750px;
        display: block;
        position: relative;
        margin-bottom: 2rem;
    }
    
    .hero::after {
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;  
        background-image: url('../../../../media/alex_steph_disney.jpg');
        height: 750px;
        background-size: cover;
        background-position: center;
        opacity: 0.7;
    }

    .hero__primary {
        font-size: 7rem;
        text-shadow: 0 0 5px white;
    }

    /** **/

    .accommodation__block {
        display: inline-block;
        width: 50%;
    }

    /** **/

    .heading {
        display: inline-block;
        background-color: black;
        color: white;
        padding: 2rem;
    }
}
