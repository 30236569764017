.margin__container {
    margin: 2rem;
}
.image {
    width: 33%;
}

.hero {
    height: 400px;
    display: block;
    position: relative;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.hero::after {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;  
    background-image: url('../../../../media/hero.png');
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

.hero__primary {
    font-size: 4rem;
    text-shadow: 0 0 5px white;
}

.hero__secondary {
    font-size: 4rem;
    text-shadow: 0 0 5px white;
    font-weight: bold;
}

.whatsUp {
    position: absolute;
    text-align: center;
    margin-top: 5%;
    width: 100%;
}

/**  **/

.itinerary {
}

.itinerary__table {
    width: 100%;
    margin: auto;
    padding: 2rem;
    border: 1px solid grey;
}

.itinerary__table th {
    padding: 2rem;
}

.itinerary__table tr {
    padding: 2rem;
}

.itinerary__table td {
    padding: 2rem;
}

/** **/

.playlist {
    margin: 1rem;
}

.playlist__frame {
    width: 100%;
    height: 40rem;
}

/**  **/

.venue {

}

.venue__block {
    display: block;
}

.venue__frame {
    width: 100%;
    height: 50rem;
    border: none;
}

/**  **/

.accommodation {
    margin: 1rem;
}

.accommodation__block {
    display: block;
    width: 100%;
}

.accommodation__block:nth-child(1) {
    margin-right: 1%;
}

.accommodation__block:nth-child(2) {
    margin-left: 1%;
}

.accommodation__frame {
    width: 100%;
    height: 40rem;
    border: none;
}

/**  **/

.food {
    background-color: #eeeeee;
    padding-bottom: 3rem;
    margin: 1rem;
}

.food__items {
    display: flex;
    justify-content: space-around;
}

.food__items__item {
    width: 33.33%;
    padding: 2rem;
}

.food__item__image {
    box-shadow:  inset 0 0 30px #000000;
    border-radius: 50%;
    background: url('../../../../media/hero.png');
    background-size: cover;
    background-position: center;
    display: inline-block;
    height: 0;
    padding-bottom: 100%;
    width: 100%;
}

/** **/

.music {
}

.music__block {
}

/** **/

.description {
    order: 1;
    flex: 2;
}

.breakdown {
    order: 2;
    flex: 1;
}
  
.heading {
    display: inline-block;
    background-color: black;
    color: white;
    padding: 2rem;
    margin: 0 0 2rem 0;
}

.punchoutLink {
    font-size: 2rem;
    padding: 1;
    display: block;
    padding: 1rem;
    text-align: right;
    font-style: italic;
}

.block {
    margin: 1rem;
}

@media screen and (min-width: 1550px) {
    .block {
        margin: 0;
    }
}

@media screen and (min-width: 950px) {

    .content {  
        flex-direction: column;
    }

    .image {
        width: 100%;
    }

    .hero {
        height: 750px;
        width: 1000px;
        margin: 5rem auto;
        display: block;
        position: relative;
        margin-bottom: 2rem;
    }
    
    .hero::after {
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;  
        background-image: url('../../../../media/hero.png');
        height: 750px;
        background-repeat: no-repeat;
        background-position: center;
    }

    .hero__primary {
        font-size: 7rem;
        text-shadow: 0 0 5px white;
    }

    .accommodation__block {
        display: inline-block;
        width: 49%;
    }

    .heading {
        display: inline-block;
        background-color: black;
        color: white;
        padding: 2rem;
    }
}
