.itinerary {
    text-align: center;
    padding: 9rem 0 6rem 0;
    font-size: 4rem;
}

.itinerary__table {
    width: 100%;
    margin: auto;
    padding: 2rem;
    border: 1px solid grey;
}

.itinerary__table th {
    padding: 2rem;
}

.itinerary__table tr {
    padding: 2rem;
}

.itinerary__table td {
    padding: 2rem;
}

div {
  box-sizing: border-box;
}

@media screen and (min-width: 950px) {
    .table-container {
        display: block;
        width: 100rem;
        margin: 2em auto;
        font-size: 1.8rem;
      }
      
}

.table-container {
  display: block;
  margin: 2em auto;
  font-size: 1.8rem;
}

.flag-icon {
  margin-right: 0.1em;
}

.flex-table {
  display: flex;
  flex-flow: row wrap;
  border-left: solid 1px var(--font-colour);
  transition: 0.5s;
}

:first-of-type.flex-table {
    border-top: solid 1px var(--font-colour);
    border-left: solid 1px var(--font-colour);
}

:first-of-type.flex-row  {
    background: var(--font-colour);
    color: white;
    border-color: var(--font-colour);
    font-weight: bold;
}

.flex-table.row:nth-child(odd) .flex-row {
    background: var(--font-colour);
}

.flex-row {
  width: calc(100% / 2);
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px var(--font-colour);
  border-bottom: solid 1px var(--font-colour);
}

.rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.column {
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;

}

.column > .flex-row {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: solid 1px var(--font-colour);
}

.flex-cell {
  width: calc(100% / 2); 
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px var(--font-colour);
}

@media all and (max-width: 767px) {
  .flex-row {
    width: calc(100% / 2); 
   &.first {
     width: 100%;
   }
  }

  .column {
    width: 100%;
  }
}

@media all and (max-width: 430px) {
  
  .flex-table {
    .flex-row {
      border-bottom: 0;
    }
    .flex-row:last-of-type {
      border-bottom: solid 1px var(--font-colour);
    }
  }
  
  .header {
    .flex-row {
      border-bottom: solid 1px;
    }
  }
  
  .flex-row {
    width: 100%; 
    
   &.first {
     width: 100%;
     border-bottom: solid 1px var(--font-colour);
   }
  }

  .column {
    width: 100%;
    .flex-row {
      border-bottom: solid 1px var(--font-colour);
    }
  }

  .flex-cell {
    width: 100%;
  }
}