@import url('http://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400&display=swap');
@font-face {
    font-family: 'Balqis';
    src: url('https://fandanzle-fonts.s3.eu-west-2.amazonaws.com/balqis.woff') format('woff'),
        url('https://fandanzle-fonts.s3.eu-west-2.amazonaws.com/balqis.ttf') format('truetype')
}

@charset "utf-8";
@-webkit-viewport   { width: device-width; }
@-moz-viewport      { width: device-width; }
@-ms-viewport       { width: device-width; }
@-o-viewport        { width: device-width; }
@viewport           { width: device-width; }

:root {
    --base-font-famiy: 'Titillium Web', sans-serif;
    --fancy-font-family: 'Balqis', cursive;
    --base-font-weight: 400;
    --base-background-colour: white;
    --base-font-size: 10px;

    --default-font-size: 1.5rem;
    --default-line-heigh: 2.4rem;
    
    --base-pad: 1rem;
 
    --header-1: 4rem;
    --header-2: 1.7rem;
    --header-3: 1.5rem;
    --header-4: 1.3rem;

    --font-colour: #b58b30;
    --base-colour:#b58b30;

    --tablet-breakpoint: 1024px;
    --mobile-breakpoint: 800px;

    --max-size: 110rem;
}

body {
    margin: 0 auto;
    padding: 0;
    font-weight: 100;
}

html {
    font-size: var(--base-font-size);
    color: var(--font-colour);
    font-weight: var(--base-font-weight);
    font-size: var(--base-font-size);
    font-family: var(--base-font-famiy);
    background-color: var(--base-background-colour);

    font-weight: 100;
    color: #b58b30;
}

.container__large {
    padding: none;
    margin: 0 2rem;
}

.container__standard {
    padding: none;
    margin: none;
}

.container--small {
    padding: none;
    margin: none;
}

.container__form {
    padding: none;
    margin: none;}

.block {
    border-bottom: 1px solid var(--base-background-colour);
    padding: 2rem;
}

.clearfix {
    clear: both;
}

/** **/

h1 {
    font-size: var(--header-1);
    font-family: var(--fancy-font-family);
}

h2 {
    color: var(--base-colour);
    font-size: var(--header-2);
    font-weight: 700;
    text-transform: uppercase;
    font-family: var(--fancy-font-family);
}

h3 {
    font-size: var(--header-3);
    font-family: var(--fancy-font-family);
}

h4 {
    font-size: var(--header-4);
    font-family: var(--fancy-font-family);
}

/** **/

a {
    text-decoration: none;
    color: var(--font-colour);
}

p {
    font-size: var(--default-font-size);
    line-height: var(--default-line-heigh);
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

img {
    width: 100%;
    animation: fadeIn ease 1s;
}

@media screen and (min-width: 950px) {

    .container__large {
        max-width: 150rem;
        margin: 0 auto
    }
    
    .container__standard {
        max-width: 120rem;
        margin: 0 auto
    }
    
    .container__small {
        max-width: 110rem;
        margin: 0 auto

    }
    
    .container__form {
        max-width: 70rem;
        margin: 0 auto
    }
    
}